/* src/components/Footer.css */
.footer {
    background-color: #e3af27;
    color: white;
    text-align: center;
    padding: 2rem 1rem;
}

.footer-contact p {
    margin-bottom: 1rem;
}

.footer-social {
    margin: 1rem 0;
}

.footer-social a {
    margin: 0 0.5rem;
    display: inline-block;
}

.footer-social img {
    height: 25px;
    width: 25px;
}

.footer p {
    margin: 0;
}

@media (max-width: 768px) {
    .footer {
        padding: 1rem;
    }

    .footer-contact p,
    .footer p {
        font-size: 0.875rem;
    }

    .footer-social img {
        height: 20px;
        width: 20px;
    }
}