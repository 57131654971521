/* src/components/AboutUs.css */
.about-us {
    background: #fcfcfc;
    padding: 4rem 0;
    text-align: center;
}

.about-us h2 {
    font-size: 2.2rem;
    color: #e3af27;
    margin-bottom: 1.5rem;
}

.about-us img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    margin-bottom: 1.5rem;
}

.about-us p {
    font-size: 1.1rem;
    color: #36aa4a;
    max-width: 800px;
    margin: 0 auto 1.5rem;
}

@media (max-width: 768px) {
    .about-us {
        padding: 2rem 1rem;
    }

    .about-us h2 {
        font-size: 1.8rem;
    }

    .about-us p {
        font-size: 1rem;
    }
}