/* src/components/Home.css */
.home {
    text-align: center;
    background: #fcfcfc;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home h1 {
    font-size: 2.5rem;
    color: #e3af27;
    margin-bottom: 1rem;
}

.home .subtitle {
    font-size: 1.1rem;
    color: #36aa4a;
    /* White color */
    margin-bottom: 1.5rem;
    max-width: 800px;
}

.home .description {
    font-size: 1.1rem;
    color: #36aa4a;
    /* White color */
    margin-bottom: 1.5rem;
    max-width: 800px;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 100%;
    max-width: 1200px;
    transition: all 0.5s ease-in-out;
}

.gallery img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .home h1 {
        font-size: 2rem;
    }

    .gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .home h1 {
        font-size: 1.5rem;
    }

    .gallery {
        grid-template-columns: 1fr;
    }
}