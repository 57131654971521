/* src/components/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fcfcfc;
  padding: 1rem 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo img {
  height: 40px;
}

.nav-links {
  display: flex;
  list-style: none;
  transition: transform 0.3s ease-in-out;
}

.nav-links li {
  margin: 0 1rem;
}

.nav-links a {
  color: #36aa4a;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
}

.nav-links a:hover {
  color: #ffffff;
}

.navbar-menu-icon {
  display: none;
  cursor: pointer;
}

.close-icon {
  display: none;
  /* Hide the close icon by default */
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #36aa4a;
}

@media (max-width: 768px) {
  .nav-links {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background: #fcfcfc;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3rem;
    width: 250px;
    transform: translateX(100%);
    box-shadow: -4px 0 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }

  .nav-links.active {
    transform: translateX(0);
  }

  .nav-links li {
    margin: 1rem 0;
    width: 100%;
  }

  .nav-links a {
    color: #36aa4a;
    padding: 1rem 2rem;
    display: block;
    width: 100%;
  }

  .navbar-menu-icon {
    display: block;
    font-size: 1.5rem;
    color: #36aa4a;
  }

  .nav-links.active .close-icon {
    display: block;
    /* Show the close icon when menu is active */
  }
}