/* src/App.css */
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6;
  background-color: #fcfcfc;
  color: #333;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

section {
  padding: 4rem 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  section {
    padding: 3rem 1rem;
  }
}