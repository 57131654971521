/* src/components/QualityProduction.css */
.quality-production {
    background: #fcfcfc;
    padding: 4rem 0;
    text-align: center;
}

.quality-production h2 {
    font-size: 2.2rem;
    color: #e3af27;
    margin-bottom: 1.5rem;
}

.quality-production p {
    font-size: 1.1rem;
    color: #36aa4a;
    margin-bottom: 1.5rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.quality-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    /* Increased gap for better spacing */
}

.quality-images img {
    width: 100%;
    max-width: 350px;
    border-radius: 10px;
}

.certificate {
    margin-top: 4rem;
    /* Increased margin for better separation */
    text-align: center;
}

.certificate h2 {
    font-size: 2.2rem;
    color: #e3af27;
    margin-bottom: 1rem;
}

.certificate p {
    font-size: 1.1rem;
    color: #36aa4a;
    margin-bottom: 1.5rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.certificate-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    /* Increased gap for better spacing */
    margin-top: 2rem;
    /* Added margin for better separation */
}

.certificate-images img {
    width: 100%;
    max-width: 300px;
    /* Reduced max-width for better fit */
    border-radius: 10px;
    margin-bottom: 2rem;
    /* Added margin for better spacing */
}

@media (max-width: 768px) {

    .quality-production h2,
    .certificate h2 {
        font-size: 1.8rem;
    }

    .quality-production p,
    .certificate p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {

    .quality-production h2,
    .certificate h2 {
        font-size: 1.5rem;
    }

    .quality-images img,
    .certificate-images img {
        max-width: 100%;
    }
}