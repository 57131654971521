/* src/components/Products.css */
.products {
    background: #fcfcfc;
    padding: 4rem 0;
}

.products h2 {
    font-size: 2.2rem;
    color: #e3af27;
    margin-bottom: 1.5rem;
    text-align: center;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.product-item {
    background-color: #fcfcfc;
    padding: 1rem;
    border-radius: 10px;
    width: calc(25% - 1rem);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.product-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.product-item img {
    border-radius: 10px;
    margin-bottom: 0.5rem;
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.product-item h3 {
    color: #36aa4a;
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.product-item p {
    color: #6fab3e;
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .product-item {
        width: calc(50% - 1rem);
    }

    .products h2 {
        font-size: 1.8rem;
    }
}

@media (max-width: 480px) {
    .product-item {
        width: 100%;
    }

    .products h2 {
        font-size: 1.5rem;
    }
}