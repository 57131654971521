/* src/components/SendInquiry.css */
.send-inquiry {
    background: #fcfcfc;
    padding: 4rem 0;
    text-align: center;
}

.send-inquiry h2 {
    font-size: 2.2rem;
    color: #e3af27;
    margin-bottom: 1.5rem;
}

.send-inquiry form {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
}

.send-inquiry input,
.send-inquiry textarea,
.send-inquiry button {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #36aa4a;
    border-radius: 5px;
    font-size: 0.9rem;
    box-sizing: border-box;
    /* Ensure padding and border are included in the width */
}

.send-inquiry button {
    background-color: #e3af27;
    color: #fff;
    border: none;
    cursor: pointer;
}

.send-inquiry button:hover {
    background-color: #a9ad32;
}

.success-notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #36aa4a;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: 0;
    animation: fadeInOut 4s forwards;
    /* 3s display + 1s fade-out */
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .send-inquiry h2 {
        font-size: 1.8rem;
    }
}

@media (max-width: 480px) {
    .send-inquiry h2 {
        font-size: 1.5rem;
    }
}